<template>
  <div>
    <v-row>
      <v-col md="6">
        <v-card class="rounded-xl py-4 px-8" flat>
          <v-toolbar class="d-flex justify-center font-weight-bold" flat>
            Email Us
          </v-toolbar>
          <small>
            For Support Issues and feedback, you can contact us at
          </small>
          <small class="d-block">
            <a href="javascript:void(0)">support@konvey.app</a>
          </small>
          <small class="d-block mt-4">
            Our Support team endeavor to respond as quickly as possible
          </small>
          <v-form class="text-left mt-6" lazy-validation ref="form">
            <label class="text-12">Your Email</label>
            <v-text-field
              rounded
              solo
              flat
              dense
              v-model="form.email"
              background-color="secondary"
              :rules="validate.required('email address')"
              placeholder="Enter your email address"
            ></v-text-field>
            <label>Message</label>
            <v-textarea
              rounded
              solo
              flat
              dense
              v-model="form.message"
              background-color="secondary"
              :rules="validate.required('message')"
              placeholder="Enter your message"
              append-icon=""
            >
              <template v-slot:append>
                <v-file-input
                  id="uploadFile"
                  style="display: none"
                  v-model="form.file"
                  accept=".jpg,.jpeg,.png"
                ></v-file-input>
                <v-icon
                  onclick="document.getElementById('uploadFile').click()"
                  class="white rounded-circle pa-2 pointer"
                  style="position: absolute; bottom: 20px; right: 20px"
                >
                  mdi-paperclip
                </v-icon>
              </template>
            </v-textarea>
            <div>{{ form.file ? "Selected " + form.file.name : null }}</div>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="$refs.form.validate() ? supportMessage() : null"
              class="primary capitalize"
              rounded
              small
              :loading="loading"
              >Send</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="rounded-xl py-4 px-8" flat>
          <v-toolbar class="d-flex justify-center" flat>
            <strong>Send Video</strong>
          </v-toolbar>
          <small>Of course you can also send us a screen recording</small>
          <small class="d-block">Thank you!</small>
          <v-card-actions class="mt-4">
            <v-spacer></v-spacer>
            <v-btn
              to="/record?support=support@konvey.app"
              class="capitalize primary px-5"
              rounded
              text
              target="_blank"
            >
              <small>send a recording</small>
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FormValidation from "../../utils/FormValidation";
import ResponseHelper from "../../utils/ResponseHelper";
export default {
  name: "Support",
  data() {
    return {
      form: {
        email: null,
      },
      validate: new FormValidation(),
      toast: new ResponseHelper(),
      loading: false,
    };
  },
  mounted() {
    this.form.email = this.user.email;
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    async supportMessage() {
      this.loading = true;
      let formData = new FormData();
      formData.append("email", this.form.email);
      formData.append("message", this.form.message);
      formData.append("file", this.form.file);
      try {
        const response = await this.$axios.post("/user/send-mail", formData);
        this.toast.sendSuccess(response);
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.toast.sendError(e);
      }
    },
  },
};
</script>

<style scoped></style>
